import {
  initCurrentExchange,
  TCurrentExchange,
  TFilteredQuery,
  TGeneralLinks,
  TModerationSocket,
} from "@/features/profile/types";
import { GeneralParamAction } from "./general_param.actions";
import general_paramTypes from "./general_param.types";

interface IGeneralParamReducerState {
  ethnies: { id: string; name: string }[];
  bodyshape: { id: string; name: string }[];
  search_query: string;
  form_query: TFilteredQuery;
  openDiscussion: boolean;
  current_exchange: TCurrentExchange;
  max_message: number;
  nbre_message_sent: number;
  nbre_message_receive: number;
  moderation_socket: TModerationSocket;
  nbre_notification_socket: number;
  nbre_live_socket: number;
  max_caracter: number;
  mail_male: string;
  mail_female: string;
  general_links: TGeneralLinks;
  is_moderation_suspend: boolean;
  message_closed_moderation: string;
  tour_step_is_open: boolean;
  is_website_maintenance: boolean;
  is_payment_confirm: boolean;
  is_payment_pending_process: boolean;
}

const INITIAL_STATE = {
  bodyshape: [],
  ethnies: [],
  search_query: "",
  form_query: {
    age_minimum: "" as number | "",
    age_maximum: "" as number | "",
    tenue_vestimentaire_femme: [],
    situation_matrimoniale: [],
    fast_filter: "" as "consulted" | "not_consulted" | "my_specttators" | "",
    situation_parentale: "" as "true" | "false" | "",
    accepte_enfants: "" as "true" | "false" | "",
    accepte_married_man: "" as "true" | "false" | "",
    profils_salafi: "" as "true" | "false" | "",
    accepte_demenager: "" as "true" | "false" | "",
    etant_hijra: "" as "true" | "false" | "",
    souhait_hijra: "" as "true" | "false" | "",
    recherche_mon_ethnie: "" as "true" | "false" | "",
    originList: [],
    ethniesList: [],
    countriesResidence: [],
  },
  openDiscussion: false,
  current_exchange: initCurrentExchange,
  max_message: 0,
  nbre_message_sent: 0,
  nbre_message_receive: 0,
  moderation_socket: {
    day: "Mardi",
    end_time: "23:59",
    is_holiday: false,
    message: "La modération est fermée.",
    start_time: "00:00",
    status: true,
  },
  nbre_notification_socket: 0,
  nbre_live_socket: 0,
  max_caracter: 100,
  mail_male: "test.male@gmail.com",
  mail_female: "test.female@gmail.com",
  general_links: {
    youtube: "https://www.youtube.com/channel/UCn68-DwUbD0OnQL7233FGPw",
    telegram: "https://www.youtube.com/channel/UCn68-DwUbD0OnQL7233FGPw",
    facebook: "https://www.facebook.com/zawajsounnah",
    twitter: "https://twitter.com/ZawajSounnah",
    instagram: "https://www.instagram.com/zawaj.sounnah/",
  },
  is_moderation_suspend: true,
  message_closed_moderation: "",
  tour_step_is_open: true,
  is_website_maintenance: false,
  is_payment_confirm: false,
  is_payment_pending_process: false,
};

const general_paramReducer = (
  state: IGeneralParamReducerState = INITIAL_STATE,
  action: GeneralParamAction
): IGeneralParamReducerState => {
  switch (action.type) {
    case general_paramTypes.UPDATE_ETHNIES:
      return {
        ...state,
        ethnies: action.payload,
      };
    case general_paramTypes.UPDATE_BODYSHAPE:
      return {
        ...state,
        bodyshape: action.payload,
      };
    case general_paramTypes.UPDATE_SEARCH_QUERY:
      return {
        ...state,
        search_query: action.payload,
      };
    case general_paramTypes.UPDATE_FORM_QUERY:
      return {
        ...state,
        form_query: action.payload,
      };
    case general_paramTypes.UPDATE_OPEN_DISCUSSION:
      return {
        ...state,
        openDiscussion: action.payload,
      };
    case general_paramTypes.UPDATE_CURRENT_EXCHANGE:
      return {
        ...state,
        current_exchange: action.payload,
      };
    case general_paramTypes.UPDATE_MAX_MESSAGE:
      return {
        ...state,
        max_message: action.payload,
      };
    case general_paramTypes.UPDATE_NBRE_MESSAGE_SENT:
      return {
        ...state,
        nbre_message_sent: action.payload,
      };
    case general_paramTypes.UPDATE_NBRE_MESSAGE_RECEIVE:
      return {
        ...state,
        nbre_message_receive: action.payload,
      };
    case general_paramTypes.UPDATE_MODERATION_SOCKET:
      return {
        ...state,
        moderation_socket: action.payload,
      };
    case general_paramTypes.UPDATE_NBRE_NOTIFICATION_SOCKET:
      return {
        ...state,
        nbre_notification_socket: action.payload,
      };
    case general_paramTypes.UPDATE_NBRE_LIVE_SOCKET:
      return {
        ...state,
        nbre_live_socket: action.payload,
      };
    case general_paramTypes.UPDATE_MAX_CARACTERE:
      return {
        ...state,
        max_caracter: action.payload,
      };
    case general_paramTypes.UPDATE_MAIL_MALE:
      return {
        ...state,
        mail_male: action.payload,
      };
    case general_paramTypes.UPDATE_MAIL_FEMALE:
      return {
        ...state,
        mail_female: action.payload,
      };
    case general_paramTypes.UPDATE_GENERAL_LINKS:
      return {
        ...state,
        general_links: action.payload,
      };
    case general_paramTypes.UPDATE_IS_MODERATION_SUSPEND:
      return {
        ...state,
        is_moderation_suspend: action.payload,
      };

    case general_paramTypes.UPDATE_MESSAGE_CLOSED_MODERATION:
      return {
        ...state,
        message_closed_moderation: action.payload,
      };

    case general_paramTypes.UPDATE_TOUR_STEP_IS_OPEN:
      return {
        ...state,
        tour_step_is_open: action.payload,
      };

    case general_paramTypes.UPDATE_IS_WEBSITE_MAINTENANCE:
      return {
        ...state,
        is_website_maintenance: action.payload,
      };

    case general_paramTypes.UPDATE_IS_PAYMENT_CONFIRM:
      return {
        ...state,
        is_payment_confirm: action.payload,
      };

    case general_paramTypes.UPDATE_PAYMENT_PENDING_PROCESS:
      return {
        ...state,
        is_payment_pending_process: action.payload,
      };

    default:
      return state;
  }
};

export default general_paramReducer;
