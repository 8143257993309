import { Routes, Route, Navigate } from "react-router-dom";
import PaymentConfirm from "../page/paymentConfirm.page";

export const PaymentConfirmRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<PaymentConfirm />} />
    </Routes>
  );
};
