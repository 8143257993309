import { PaymentConfirmRoutes } from "@/features/payment-confirm";
import { Navigate, RouteObject } from "react-router-dom";

export const paymentConfirmedRoutes: RouteObject[] = [
  {
    path: "/zs-confirm-payment",
    element: <PaymentConfirmRoutes />,
  },
  { path: "/*", element: <Navigate to="/zs-confirm-payment" /> },
];
