import React, { useContext, useEffect, useState } from "react";
import {
  YouTube,
  Send,
  FacebookRounded,
  Twitter,
  Instagram,
} from "@mui/icons-material";
import logo from "@/assets/images/png/logo.png";
import logoMini from "@/assets/images/png/logo-mini.png";
import under from "@/assets/images/png/UnderContruction.png";
import "./paymentConfirm.styles.scss";
import queryString from "query-string";
import { apiGetUserConnected } from "@/features/auth/api/auth.api";
import { TMemberProfile } from "@/features/auth/types";
import { TMemberInfos } from "@/features/profile/types";
import { store } from "@/store";
import { useSelector } from "react-redux";
import {
  updateIsPaymentConfirm,
  updateIsPaymentPendingProcess,
} from "@/store/reducers/general_param/general_param.actions";
import {
  getMemberInfos,
  updateMemberProfile,
  updateMemberCurrentExchange,
  updateIsOnline,
} from "@/store/reducers/member/member.actions";
import { Toastify } from "@/utils/toast";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { InlineIcon } from "@iconify/react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";

const PaymentConfirm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const goToHome = () => {
    store.dispatch(updateIsPaymentConfirm(false));
    navigate(routePaths.profileChildren.homeAbsolute, { replace: true });
  };

  useEffect(() => {
    let maxTries = 10; // Nombre maximum de tentatives
    let tries = 0;
    setLoading(true);
    store.dispatch(updateIsPaymentPendingProcess(true));
    const manageDelayId = setInterval(() => {
      tries++;
      console.log(`Tentative ${tries}`);
      console.log(" payment confirm ici ");

      apiGetUserConnected()
        .then((response: any) => {
          const profile = response.data as TMemberProfile;
          console.log({ profile });
          const infosUser: TMemberInfos = {
            _id: profile.member._id,
            email: profile.member.email,
            role: profile.member.role,
            username: profile.member.username,
            memberId: profile.member.memberId,
            isVerified: store.getState().member.member_infos!?.isVerified,
            isInEmailVerificationProcess:
              store.getState().member.member_infos!
                ?.isInEmailVerificationProcess,
          };
          console.log({ infosUser });
          store.dispatch(getMemberInfos({ ...infosUser }));
          store.dispatch(updateMemberProfile({ ...profile }));
          store.dispatch(
            updateMemberCurrentExchange(profile?.currentConversation || null)
          );
          store.dispatch(updateIsOnline(profile.availability.isAvailable));

          if (profile.account.subscription.lastPayment.hasExpired === false) {
            clearInterval(manageDelayId); // Arrête l'intervalle une fois que le paiement est valide
            setLoading(false);
            store.dispatch(updateIsPaymentPendingProcess(false));
            Toastify("Paiement effectué avec succès", "success", 2000);
          }

          if (profile.account.subscription.isFree.value === true) {
            clearInterval(manageDelayId); // Arrête l'intervalle une fois que le paiement est valide
            setLoading(false);
            store.dispatch(updateIsPaymentPendingProcess(false));
            Toastify("Paiement effectué avec succès", "success", 2000);
          }
        })
        .catch((error) => {
          console.log("😜", error);
        })
        .finally(() => {});

      // Ajoutez ici votre logique principale à exécuter à chaque intervalle

      if (tries >= maxTries) {
        clearInterval(manageDelayId);
        setLoading(false); // Arrête l'intervalle après avoir atteint le nombre max de tentatives
        console.log("Nombre maximum de tentatives atteint. Intervalle arrêté.");
        store.dispatch(updateIsPaymentPendingProcess(false));
        window.location.reload();
      }
    }, 2500);
  }, []);

  return (
    <div className="payment-confirm-container">
      <div className="top">
        <img src={logo} alt="" className="logo" />
      </div>
      <div className="big-title">
        {loading ? (
          <>
            <p>
              {" "}
              Paiement 
              <span className="color-text">en cours de traitement</span>.{" "}
            </p>
          </>
        ) : (
          <>
            <p>
              {" "}
              Paiement 
              <span className="color-text">effectué avec succès</span>.{" "}
            </p>
          </>
        )}
      </div>
      <div className="middle">
        <img src={logoMini} alt="" className="logo-mini" />

        {loading ? (
          <>
            <p style={{ textAlign: "center" }}>
              Merci d'avoir procédé au paiement de votre abonnement.
              <br />
              ... Veuillez patienter quelques instants ...
            </p>
          </>
        ) : (
          <>
            <p style={{ textAlign: "center" }}>
              {" "}
              L'équipe Zawaj Sounnah vous remercie de la confiance que vous leur
              accordez.{" "}
            </p>

            <Button
              variant="contained"
              color="primary"
              onClick={goToHome}
              startIcon={<InlineIcon icon="bx:home" />}
              className="btn-home"
            >
              Retourner à mon espace
            </Button>
          </>
        )}
        <p style={{ marginTop: "auto", textAlign: "center" }}>
          Pour toute(s) autre(s) question(s) contactez-nous par e-mail :{" "}
          <b>
            <a href="mailto:zawaj.sounnah@gmail.com">zawaj.sounnah@gmail.com</a>
          </b>
        </p>
      </div>
      <div className="bottom">
        <div className="copyright">
          <p>
            © 2024 
            <a href="https://zawaj-sounnah.com/">Zawaj Sounnah</a>.<br />
            <small>Une cause pour le mariage</small>
          </p>
        </div>
        <div className="social">
          <a
            className="rounded-square"
            href="https://www.youtube.com/@zawajsounnah2059"
            target="_blank"
            rel="noreferrer"
          >
            <YouTube color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://t.me/zawajsounnah"
            target="_blank"
            rel="noreferrer"
          >
            <Send color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://www.facebook.com/zawajsounnah/"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookRounded color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://twitter.com/ZawajSounnah?t=E5cbH2yqGe6ws_X_ZIOVxQ&s=09"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter color="primary" />
          </a>
          <a
            className="rounded-square"
            href="https://www.instagram.com/zawaj.sounnah/?hl=fr"
            target="_blank"
            rel="noreferrer"
          >
            <Instagram color="primary" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirm;
